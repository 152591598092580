import { send } from '@emailjs/browser';

export const sendSignUpEmail = async ({
  email,
  firstname,
  amount,
  teamname,
  club,
}: {
  email: string;
  firstname: string;
  amount: number;
  teamname: string;
  club: 'svsibbe' | `vvsec`;
}) => {
  const templateParams = {
    first_name: firstname,
    to_email: email,
    amount,
    team_name: teamname,
    club: club === `svsibbe` ? `SV Sibbe` : `VC S.E.C.`,
    bank:
      club === `svsibbe`
        ? `NL32RABO0147301157 t.n.v. SVSibbe`
        : ` NL12INGB0679817999 t.n.v. VC S.E.C.`,
    organisation_beach_event:
      club === `svsibbe`
        ? `Organisatie Beachevent`
        : `Organisatie Beachevent & VC S.E.C.`,
  };
  return send(
    `service_6xq9d0a`,
    `template_iuoy8no`,
    templateParams,
    `E5mmNIe-AdAU0BSg0`,
  );
};
