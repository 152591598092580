import React from 'react';

interface Props {
  email: string;
}

export default function SuccessCard({ email = `` }: Props) {
  return (
    <div className="bg-gray-300 rounded flex-row flex h-96 self-center lg:mt-20">
      <div className="w-full lg:w-1/2 p-12 flex items-center justify-center flex-col">
        <div className="text-secondary text-xl font-bold mb-6">
          Bedankt voor je inschrijving!
        </div>
        <span className="text-base font-normal">
          {`Een bevestigingsmail is verzonden naar: ${email}. Volg de instructies in deze mail om de inschrijving definitief te maken`}
        </span>
      </div>
      <div className="hidden lg:block lg:w-1/2 ">
        <iframe
          title="Aftermovie 2019"
          className="w-full h-full rounded"
          src="https://www.youtube.com/embed/_bT4axJWG-8?controls=1&amp;start=160&mute=1&autoplay=1"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture no"
          allowFullScreen
        />
      </div>
    </div>
  );
}
