import { Layout } from '@/layout';
import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { encode } from 'querystring';
import { Content } from '@/components';
import * as yup from 'yup';
import { sendSignUpEmail } from '@/utils/send-signup-email';
import SuccessCard from '@/components/form/SuccessCard';
import Loader from '@/components/loader/Loader';

const validationScheme = yup.object().shape({
  [`first-name`]: yup.string().required(`Verplicht`),
  [`last-name`]: yup.string().required(`Verplicht`),
  [`email-address`]: yup
    .string()
    .email(`Geen geldig email adres`)
    .required(`Verplicht`),
  [`team-name`]: yup
    .string()
    .required(`Verplicht`)
    .min(2, `Minimaal 2 karakters`)
    .max(20, `Maximaal 20 karakters`),
  street: yup.string().required(`Verplicht`),
  [`postal-code`]: yup.string().required(`Verplicht`),
  [`city`]: yup.string().required(`Verplicht`),
});

interface FormValues {
  'first-name': string;
  'last-name': string;
  'email-address': string;
  'team-name': string;
  street: string;
  'postal-code': string;
  city: string;
  phonenumber: string;
  vrijdag: string;
  zaterdagmiddag: string;
  zaterdagavond: string;
  zondag: string;
}

// const vrijdag = [
//   {
//     id: `vrijdag-volwassenen`,
//     title: `Voetvolley Vrijdag volwassenen`,
//     day: `friday`,
//     amount: 15,
//     full: true,
//   },
//   {
//     id: `vrijdag-jeugd`,
//     title: `Voetvolley Vrijdag jeugd tot 18 jaar`,
//     day: `friday`,
//     amount: 15,
//     full: true,
//   },
// ];

// const zaterdag = [
//   {
//     id: `zaterdag-morgen-jo7`,
//     title: `Beachvoetbal Zaterdag - JO7`,
//     day: `saturday`,
//     amount: 15,
//   },
//   {
//     id: `zaterdag-morgen-jo9`,
//     title: `Beachvoetbal Zaterdag - JO9`,
//     day: `saturday`,
//     amount: 15,
//   },
//   {
//     id: `zaterdag-morgen-jo11`,
//     title: `Beachvoetbal Zaterdag - JO11`,
//     day: `saturday`,
//     amount: 15,
//   },
//   {
//     id: `zaterdag-morgen-jo13`,
//     title: `Beachvoetbal Zaterdag - JO13`,
//     day: `saturday`,
//     amount: 15,
//   },
//   {
//     id: `zaterdag-morgen-jo15`,
//     title: `Beachvoetbal Zaterdag - JO15`,
//     day: `saturday`,
//     amount: 15,
//   },
//   {
//     id: `zaterdag-morgen-jo17`,
//     title: `Beachvoetbal Zaterdag - JO17`,
//     day: `saturday`,
//     amount: 15,
//   },
//   {
//     id: `zaterdag-morgen-jo19`,
//     title: `Beachvoetbal Zaterdag - JO19`,
//     day: `saturday`,
//     amount: 15,
//   },
// ];

// const zaterdagavond = [
//   {
//     id: `zaterdag-avond-recreatief`,
//     title: `Voetvolley Zaterdag - recreatief`,
//     day: `saturday-evening`,
//     amount: 15,
//   },
//   {
//     id: `zaterdag-avond-vereniging`,
//     title: `Voetvolley Zaterdag - vereniging`,
//     day: `saturday-evening`,
//     amount: 25,
//   },
// ];

// const zondag = [
//   {
//     id: `Heren-hoog`,
//     title: `Heren hoog`,
//     subtitle: `Indicatieniveau 2e klasse en hoger`,
//     day: `sunday`,
//     amount: 15,
//     full: true,
//   },
//   {
//     id: `Heren-laag`,
//     title: `Heren laag`,
//     subtitle: `Indicatieniveau 3e en 4e klasse)`,
//     day: `sunday`,
//     amount: 15,
//     full: true,
//   },
//   {
//     id: `Heren-recreanten`,
//     title: `Heren recreanten`,
//     subtitle: `Recreanten, familie- en vriendenteams.`,
//     day: `sunday`,
//     amount: 15,
//     full: true,
//   },
//   {
//     id: `Dames-hoog`,
//     title: `Dames hoog`,
//     subtitle: `Indicatieniveau 2e klasse en hoger`,
//     day: `sunday`,
//     amount: 15,
//     full: true,
//   },
//   {
//     id: `Dames-laag`,
//     title: `Dames laag`,
//     subtitle: `Indicatieniveau 3e en 4e klasse)`,
//     day: `sunday`,
//     amount: 15,
//     full: true,
//   },
//   {
//     id: `Dames-recreanten`,
//     title: `Dames recreanten`,
//     subtitle: `Recreanten, familie- en vriendenteams.`,
//     day: `sunday`,
//     amount: 15,
//     full: true,
//   },
//   {
//     id: `Gemengd-hoog`,
//     title: `Gemengd Hoog`,
//     subtitle: `Indicatieniveau 2e klasse en hoger`,
//     day: `sunday`,
//     amount: 15,
//     full: true,
//   },
//   {
//     id: `Gemengd-laag`,
//     title: `Gemengd Laag`,
//     subtitle: `Indicatieniveau 3e en 4e klasse`,
//     day: `sunday`,
//     amount: 15,
//     full: true,
//   },
//   {
//     id: `Gemengd-recreatief`,
//     title: `Gemengd recreatief`,
//     subtitle: `Recreanten, familie- en vriendenteams`,
//     day: `sunday`,
//     amount: 15,
//     full: true,
//   },
//   {
//     id: `B-jeugd`,
//     title: `B jeugd (maximaal 4 spelers)`,
//     subtitle: ``,
//     day: `sunday`,
//     amount: 15,
//     full: false,
//   },
//   {
//     id: `C-jeugd`,
//     title: `C jeugd (maximaal 4 spelers)`,
//     subtitle: ``,
//     day: `sunday`,
//     amount: 15,
//     full: false,
//   },
// ];

export default function inschrijfformulier() {
  const [showSuccessMessage, setShowSuccess] = useState(false);
  const [emailAddres, setEmailAddress] = useState(``);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values: FormValues) => {
    setLoading(true);
    try {
      let inschrijfGeldSV = 0;

      if (values?.vrijdag) {
        inschrijfGeldSV += 15;
      }
      if (values?.zaterdagmiddag) {
        inschrijfGeldSV += 15;
      }
      if (values?.zaterdagavond) {
        inschrijfGeldSV += 15;
      }

      await fetch(`/?no-cache=1`, {
        method: `POST`,
        headers: { 'Content-Type': `application/x-www-form-urlencoded` },
        body: encode({ 'form-name': `inschrijf-formulier`, ...values }),
      });

      // SVSIBBE
      if (inschrijfGeldSV > 0) {
        await sendSignUpEmail({
          amount: inschrijfGeldSV,
          email: values[`email-address`],
          firstname: values?.[`first-name`],
          teamname: values?.[`team-name`],
          club: `svsibbe`,
        });
      }
      // VVSEC
      if (values?.zondag?.length > 0) {
        await sendSignUpEmail({
          amount: 15,
          email: values[`email-address`],
          firstname: values?.[`first-name`],
          teamname: values?.[`team-name`],
          club: `vvsec`,
        });
      }

      setEmailAddress(values[`email-address`]);
      setShowSuccess(true);
    } catch (e) {
      alert(e);
    }
    setLoading(false);
  };

  return (
    <Layout>
      <Content>
        {!showSuccessMessage ? (
          <Formik
            validationSchema={validationScheme}
            initialValues={{
              'first-name': ``,
              'last-name': ``,
              'email-address': ``,
              'team-name': ``,
              city: ``,
              street: ``,
              'postal-code': ``,
              phonenumber: ``,
              vrijdag: null,
              zaterdagmiddag: null,
              zaterdagavond: null,
              zondag: null,
            }}
            onSubmit={onSubmit}
          >
            {() => (
              <Form data-netlify name="inschrijf-formulier">
                <>
                  <div className="pt-10">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                      <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                          <h3 className="text-lg font-medium leading-6 text-white">
                            Informatie contactpersoon
                          </h3>
                          <p className="mt-1 text-sm text-white">
                            Informatie contactpersoon: Jouw gegevens worden
                            enkel gebruikt voor alle communicatie doeleinde
                            omtrent het Beach Event Sv Sibbe.
                          </p>
                        </div>
                      </div>
                      <div className="mt-5 md:mt-0 md:col-span-2">
                        <div className="shadow overflow-hidden sm:rounded-md bg-background-500 relative z-10">
                          <Loader loading={loading}>
                            <div className="px-4 py-5 sm:p-6">
                              <div className="text-lg font-semibold">
                                Inschrijvingen gesloten
                              </div>
                              {/* <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6 sm:col-span-3">
                                  <TextInput
                                    identifier="first-name"
                                    label="Voornaam"
                                  />
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                  <TextInput
                                    identifier="last-name"
                                    label="Achternaam"
                                  />
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                  <TextInput
                                    identifier="email-address"
                                    label="Emailadres"
                                  />
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                  <TextInput
                                    identifier="phonenumber"
                                    label="Telefoonnummer"
                                  />
                                </div>

                                <div className="col-span-6">
                                  <TextInput
                                    identifier="street"
                                    label="Straat en huisnummer"
                                  />
                                </div>

                                <div className="col-span-6 sm:col-span-6 lg:col-span-3">
                                  <TextInput identifier="city" label="Stad" />
                                </div>

                                <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                                  <TextInput
                                    identifier="postal-code"
                                    label="Postcode"
                                  />
                                </div>
                              </div> */}
                            </div>
                          </Loader>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="hidden sm:block" aria-hidden="true">
                    <div className="py-5">
                      <div className="border-t border-gray-200" />
                    </div>
                  </div>

                  {/* <div className="mt-10 sm:mt-0">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                      <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                          <h3 className="text-lg font-medium leading-6 text-white">
                            Team informatie
                          </h3>
                        </div>
                      </div>
                      <div className="mt-5 md:mt-0 md:col-span-2">
                        <div className="shadow overflow-hidden sm:rounded-md bg-background-500 relative">
                          <Loader loading={loading}>
                            <div className="px-4 py-5">
                              <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                  <TextInput
                                    identifier="team-name"
                                    label="Teamnaam"
                                  />
                                </div>
                                <div className="col-span-6">
                                  <label className="text-base font-medium text-gray-600">
                                    Voor welke dag(en) wil je je inschrijven?
                                    <p className="text-sm text-gray-400 font-light italic">
                                      Je kunt je inschrijven voor meerdere
                                      dagen. Je ontvangt na het versturen van
                                      dit formulier een email met verdere
                                      instructies voor het betalen van het
                                      inschrijfgeld.
                                    </p>
                                    <br />
                                    <span className="text-primary text-sm font-bold mt">
                                      Het inschrijfgeld bedraagt €15* euro per
                                      onderdeel per team
                                    </span>
                                    <br />
                                    <span className="text-sm text-gray-400 font-light italic">
                                      *Teams die volledig bestaan uit spelers
                                      van SVSibbe of SJO Krijtland zijn
                                      vrijgesteld van inschrijfgeld
                                    </span>
                                  </label>
                                  <div className=" grid grid-cols-6">
                                    <div className="col-span-6 mt-4 lg:col-span-2">
                                      <label className="text-base font-normal text-third">
                                        Vrijdagavond (Beachvoetvolley)
                                      </label>
                                      {vrijdag?.map((item) => (
                                        <div
                                          key={item.id}
                                          className="items-center"
                                        >
                                          <label
                                            htmlFor={item.id}
                                            className={`text-sm font-normal text-gray-700 items-center ${
                                              item?.full ? `line-through` : ``
                                            }`}
                                          >
                                            <Field
                                              type="checkbox"
                                              disabled={
                                                (values?.vrijdag?.length &&
                                                  values?.vrijdag[0] !==
                                                    item?.id) ||
                                                item?.full
                                              }
                                              name="vrijdag"
                                              value={item?.id}
                                              className="focus:ring-primary h-4 w-4 text-primary border-gray-300 mr-4"
                                            />
                                            {item.title}
                                          </label>
                                          {item?.full && (
                                            <span className="text-third">
                                              (vol)
                                            </span>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                    <div className="col-span-6 mt-4 lg:col-span-2">
                                      <label className="text-base font-normal text-third">
                                        Zaterdag ochtend/middag (Beachvoetbal)
                                      </label>
                                      {zaterdag?.map((item) => (
                                        <div
                                          key={item.id}
                                          className="items-center"
                                        >
                                          <label
                                            htmlFor={item.id}
                                            className="text-sm font-normal text-gray-700 items-center"
                                          >
                                            <Field
                                              type="checkbox"
                                              disabled={
                                                values?.zaterdagmiddag
                                                  ?.length &&
                                                values?.zaterdagmiddag[0] !==
                                                  item?.id
                                              }
                                              name="zaterdag"
                                              value={item?.id}
                                              className="focus:ring-primary h-4 w-4 text-primary border-gray-300 mr-4"
                                            />
                                            {item.title}
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                    <div className="col-span-6 mt-4 lg:col-span-2">
                                      <label className="text-base font-normal text-third">
                                        Zaterdagavond (Beachvoetvolley)
                                      </label>
                                      {zaterdagavond?.map((item) => (
                                        <div
                                          key={item.id}
                                          className="items-center"
                                        >
                                          <label
                                            htmlFor={item.id}
                                            className="text-sm font-normal text-gray-700 items-center"
                                          >
                                            <Field
                                              type="checkbox"
                                              disabled={
                                                values?.zaterdagavond?.length &&
                                                values?.zaterdagavond[0] !==
                                                  item?.id
                                              }
                                              name="zaterdagavond"
                                              value={item?.id}
                                              className="focus:ring-primary h-4 w-4 text-primary border-gray-300 mr-4"
                                            />
                                            {item.title}
                                          </label>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                  <div className="col-span-6 mt-4 lg:col-span-2">
                                    <label className="text-base font-normal text-third">
                                      Zondag (Beachvolleybal)**
                                      <p className="text-sm text-gray-400 font-light italic">
                                        Georganiseerd door volleybalclub S.E.C.
                                        Valkenburg
                                      </p>
                                    </label>
                                    {zondag?.map((item) => (
                                      <div
                                        key={item.id}
                                        className="items-start flex-col flex mt-1"
                                      >
                                        <div className="flex flex-row items-center">
                                          <label
                                            htmlFor={item.id}
                                            className={`text-sm font-normal text-gray-700 items-center ${
                                              item?.full ? `line-through` : ``
                                            }`}
                                          >
                                            <Field
                                              type="checkbox"
                                              disabled={
                                                (values?.zondag?.length &&
                                                  values?.zondag[0] !==
                                                    item?.id) ||
                                                item?.full
                                              }
                                              name="zondag"
                                              value={item?.id}
                                              className="focus:ring-primary h-4 w-4 text-primary border-gray-300 mr-4"
                                            />
                                            {item.title}
                                          </label>
                                          {item?.full && (
                                            <span className="text-third">
                                              (vol)
                                            </span>
                                          )}
                                        </div>
                                        <span className="text-sm text-gray-400 italic pl-8">
                                          {item?.subtitle}
                                        </span>
                                      </div>
                                    ))}
                                  </div>

                                  <ErrorMessage
                                    name="inschrijfdag"
                                    render={(msg: string) => (
                                      <div className="text-sm text-red-400 mt-3">
                                        {msg}
                                      </div>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="mt-10 text-sm font-extralight italic text-gray-400">
                                **Het is mogelijk om een team in meerdere
                                klassen in te schrijven. Gebruik hiervoor een
                                nieuw inschrijfformulier.
                              </div>
                            </div>
                          </Loader>
                          <div className="px-4 py-3  text-right sm:px-6">
                            <button
                              disabled={loading}
                              type="submit"
                              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-primary bg-secondary hover:color-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              Inschrijven
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </>
              </Form>
            )}
          </Formik>
        ) : (
          <SuccessCard email={emailAddres} />
        )}
      </Content>
    </Layout>
  );
}
